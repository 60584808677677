export const style = {
  inpuStyle: {
    colorInput: '#EE7202'
  },
  url: {
    urlClient: 'https://testzone.prd.appnoz.com.br/',
    urlClientYouseed: 'https://admyouseed.bancosemear.com.br/'
  },
  fileUrl: {
    logo: 'https://timcoo-geral.s3.amazonaws.com/Logos/suvinil.png',
    secondaryLogonLogo: 'null'
  },
  variables: {
    customMenuItem: false,
    autoRegister: true,
    recaptchaVersion: 'V1'
  },
  CurrencyName: {
    name: 'Ponto/Pontos'
  },
  teamName: {
    name: 'Pintou Parceria Suvinil'
  },
  hasToken: false,
  hostHeader: 'testzone'
};
